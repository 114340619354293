.imperial-ai {
  display: flex;
  flex-direction: column;
  height: 80vh; /* Make it full height */
}

.contact-info {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Align items to the left and right edges */
  padding: 16px;
  background-color: rgba(232, 201, 118, 0.9);
  border-bottom: 1px solid #000000;
  border-top-left-radius: 16px; /* Add radius to top left corner */
  border-top-right-radius: 16px; /* Add radius to top right corner */
}

.contact-details {
  display: flex;
  align-items: center;
  justify-content: center; /* Center the avatar and name horizontally */
  flex: 1; /* Take up all available space between the avatar and toggle switch */
}

.contact-avatar {
  margin-right: 16px;
}

.contact-name {
  font-weight: 500;
  margin-left: 16px; /* Move the contact name to the far left */
}

.toggle-wrapper {
  display: flex;
  align-items: center;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 20px;
  margin-left: 8px; /* Increase this value to move the toggle further to the right */
}

.toggle-switch::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: white;
  top: 2px;
  left: 2px;
  transition: transform 0.3s;
}

.toggle-switch.toggled {
  background-color: #66a9f1;
}

.toggle-switch.toggled::before {
  transform: translateX(20px);
}

.chat-container {
  flex: 1;
  overflow-y: auto;
  padding: 16px;
  display: flex;
  flex-direction: column;
  background-color: rgba(16, 13, 34, 0.1); /* Semi-transparent background color */
}


.dark-mode .chat-container {
  background-color: rgba(16, 13, 34, 0.1);  /* Dark mode background color */
}

.message {
  display: inline-block;
  max-width: 75%;
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 8px;
  word-wrap: break-word;
}

.user {
  align-self: flex-end;
  background-color: #007aff;
  color: #fff;
}

.chatbot {
  align-self: flex-start;
  background-color: #f2f2f2;
}

.input-container {
  display: flex;
  gap: 16px;
  align-items: center;
  margin-top: 16px;
  border-radius: 8px;
  padding: 4px;
  background-color: rgba(255, 255, 255, 0);
}

.input-field {
  flex: 1;
  padding: 8px;
  border-radius: 8px;
  border: none;
  outline: none;
}

.send-button {
  border-radius: 8px;
  padding: 8px;
}

/* Add a wrapper for the toggle switch */
.toggle-wrapper {
  margin-left: auto; /* Push the toggle switch to the right */
  display: flex;
  align-items: center;
}


/* Style for the toggle switch */
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 20px;
  margin-left: 8px; /* Increase this value to move the toggle further to the right */
}


/* Style for the switch thumb (circle) */
.toggle-switch::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: white;
  top: 2px;
  left: 2px;
  transition: transform 0.3s;
}

/* Add styles for the toggled state */
.toggle-switch.toggled {
  background-color: #66a9f1;
}

/* Move the switch thumb to the right when toggled */
.toggle-switch.toggled::before {
  transform: translateX(20px);
}
