@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;800&display=swap');

body {
    background-image: url("../img/cardimgfree.png");
    background-repeat: no-repeat;
    background-size: cover;
    font-family: "Montserrat";
}

.main {
    max-width: 960px;
    margin: 0px auto;
    padding: 0px 15px;
    min-height: 100vh;
}

.header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0px;
}

.notes__title {
    color: #fff;
    font-size: 38px;
    font-family: "Montserrat";
    font-weight: 400;
}

.notes {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(3, minmax(300px, 1fr));
}

@media screen and (max-width: 900px) {
    .notes {
        grid-template-columns: repeat(2, minmax(300px, 1fr));
    }
}

@media screen and (max-width: 500px) {
    .notes {
        grid-template-columns: repeat(1, minmax(300px, 1fr));
    }
}
